html {
  height: 100%;
}

body {
  background-attachment: fixed; 
  margin: 0;
  background-color: #FF3CAC;
  background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
}

.App {
  margin: 7% 11%;
  padding: 10px;
}

.glass {
  min-height: 20rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);   
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
  padding: 10px;
}

.glass:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  filter: blur(10px);
  margin: -20px;
}